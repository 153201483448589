
import { defineComponent } from "vue";
import { Bubble } from "@/plugins/firebase/bubbles";
import { formatDate } from "@/composables/formats";

export default defineComponent({
  name: "History Item",

  props: {
    id: { type: String, required: true },
  },

  data: () => ({
    bubble: undefined as undefined | Bubble,
    avatarId: 0,
    loadingInfo: true,
    loadingAvatar: true,
  }),

  computed: {
    loading() {
      return this.loadingInfo || this.loadingAvatar;
    },
    avatarUrl() {
      return "/img/avatars/" + this.avatarId + ".png";
    },

    files() {
      const result = this.bubble?.files ?? [];
      this.$idb.files.state
        .filter((f) => f.bubbleId == this.id)
        .map((f) => f.id)
        .forEach((id) => {
          if (!result.includes(id)) result.push(id);
        });
      return result;
    },
    filesCount() {
      const n = this.files.length;
      const l = n % 10;
      const l1 = ((n - (n % 10)) / 10) % 10;
      let result = n + " ";

      if (l1 != 1 && l == 1) result += "файл";
      else if (l1 != 1 && l >= 2 && l <= 4) result += "файла";
      else result += "файлов";

      return result;
    },
  },

  watch: {
    id() {
      this.init();
    },
    bubble() {
      this.initAvatar();
    },
  },

  methods: {
    formatDate,

    open() {
      this.$router.push({ name: "History", params: { id: this.id } });
    },

    init() {
      this.loadingInfo = true;
      this.$fb.bubbles
        .get(this.id)
        .then((val) => (this.bubble = val))
        .catch(() => this.init())
        .finally(() => (this.loadingInfo = false));
    },
    initAvatar() {
      if (!this.bubble) return;

      this.loadingAvatar = true;
      this.$fb.users
        .getUser(this.bubble.creator)
        .then((val) => (this.avatarId = val?.avatar ?? 0))
        .catch(() => this.initAvatar())
        .finally(() => (this.loadingAvatar = false));
    },
  },

  mounted() {
    this.init();
  },
});
