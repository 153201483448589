
import { defineComponent } from "vue";
import QrcodeVue from "qrcode.vue";

const ROUTES = ["/b/", "/h/", "/f/", "/history/"];
function checkBack() {
  const path: string = window?.history.state?.back ?? "";
  return ROUTES.some((r) => path.startsWith(r));
}

export default defineComponent({
  name: "Share Bubble",

  props: {
    id: { type: String, default: "" },
  },

  components: { QrcodeVue },

  computed: {
    show: {
      get() {
        return "share" in this.$route.query;
      },
      set(v: boolean) {
        if (v) this.$router.push({ query: { share: null } });
        else if (checkBack()) this.$router.back();
        else this.$router.replace({ name: "History", params: { id: this.id } });
      },
    },
    link() {
      return `${window.location.origin}/b/${this.id}`;
    },

    component() {
      return this.$device.isDesktop ? "Dialog" : "Popup";
    },
  },

  methods: {
    cope(e: Event) {
      const el = e.target as HTMLElement;
      el.classList.add("copied");
      navigator.clipboard.writeText(this.link);
      this.$device.toast("Скопировано!", 99999);
    },
  },
});
