
import Item from "./file-item.vue";
import QrCode from "./qr-code.vue";
import { User } from "@/plugins/firebase/users";
import { Bubble } from "@/plugins/firebase/bubbles";
import { formatDate } from "@/composables/formats";
import { defineComponent } from "vue";

const ROUTES = ["/b/", "/h/", "/f/", "/history/"];
const URL_REGEX = /(https?:\/\/[^\s]+)/g;
const TAGS2REPLACE = { "&": "&amp;", "<": "&lt;", ">": "&gt;" };

export default defineComponent({
  name: "History Viewer",

  components: { Item, QrCode },

  data: () => ({
    bubble: undefined as undefined | Bubble,
    creator: undefined as undefined | User,
    loadingBubble: true,
    loadingCreator: false,
  }),

  computed: {
    id() {
      const id = this.$route.params.id;
      return typeof id === "string" ? id : id?.[0];
    },
    show: {
      get() {
        return !!this.id;
      },

      set(val: boolean) {
        if (val) {
          this.$router.push({ name: "History", params: { id: this.id } });
        } else if (ROUTES.includes(window?.history.state?.back)) {
          this.$router.back();
        } else {
          this.$router.replace({ name: "History" });
        }
      },
    },
    loading() {
      return this.loadingBubble || this.loadingCreator;
    },

    files() {
      if (!this.id) return [];

      const result = this.bubble?.files ?? [];
      this.$idb.files.state
        .filter((f) => f.bubbleId == this.id)
        .map((f) => f.id)
        .forEach((id) => {
          if (!result.includes(id)) result.push(id);
        });
      return result;
    },
    avatarUrl() {
      return "/img/avatars/" + (this.creator?.avatar ?? 0) + ".png";
    },
    filesCount() {
      const n = this.files.length;
      const l = n % 10;
      const l1 = ((n - (n % 10)) / 10) % 10;
      let result = n + " ";

      if (l1 != 1 && l == 1) result += "файл";
      else if (l1 != 1 && l >= 2 && l <= 4) result += "файла";
      else result += "файлов";

      return result;
    },
    description() {
      const desc = this.bubble?.description ?? "";
      return desc
        .replace(/[&<>]/g, (tag) =>
          !(tag in TAGS2REPLACE)
            ? tag
            : TAGS2REPLACE[tag as keyof typeof TAGS2REPLACE]
        )
        .replace(URL_REGEX, (url) => {
          return `<a href="${url}" target="_blank">${url}</a>`;
        });
    },
  },

  watch: {
    id(v) {
      if (v) this.init();
      else this.bubble = undefined;
    },
    bubble(v) {
      if (v) this.initCreator();
      else this.creator = undefined;
    },
  },

  methods: {
    formatDate,

    init() {
      if (!this.id) return;
      this.loadingBubble = true;
      this.$fb.bubbles
        .get(this.id)
        .then((val) => (this.bubble = val))
        .catch(() => this.init())
        .finally(() => (this.loadingBubble = false));
    },
    initCreator() {
      if (!this.bubble) return;

      this.loadingCreator = true;
      this.$fb.users
        .getUser(this.bubble.creator)
        .then((val) => (this.creator = val))
        .catch(() => this.initCreator())
        .finally(() => (this.loadingCreator = false));
    },
  },

  mounted() {
    this.init();
  },
});
