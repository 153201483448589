
import { formatSize } from "@/composables/formats";
import { defineComponent } from "vue";

export default defineComponent({
  name: "File Item",

  props: {
    id: { type: Number, required: true },
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    file() {
      return this.$fb.files.state.find((f) => f.id === this.id);
    },

    size() {
      if (!this.file) return "";
      return !this.file.transferredSize
        ? formatSize(this.file.size)
        : formatSize(this.file.transferredSize) +
            " / " +
            formatSize(this.file.size);
    },
  },

  watch: {
    id() {
      this.init();
    },
  },

  methods: {
    formatSize,
    getExtension(filename: string) {
      return filename.match(/\.(\w+)$/)?.[1] ?? "";
    },

    init() {
      this.loading = true;

      this.$fb.files
        .get(this.id)
        .catch(() => this.init())
        .finally(() => (this.loading = false));
    },
    remove() {
      this.$fb.files.remove(this.id);
    },
    download() {
      if (!this.file?.downloadURL) return;

      const a = document.createElement("a");
      a.href = this.file.downloadURL;
      a.download = this.file.name;
      a.click();
    },
  },

  mounted() {
    this.init();
  },
});
