import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "history" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopAppBar = _resolveComponent("TopAppBar")!
  const _component_Item = _resolveComponent("Item")!
  const _component_View = _resolveComponent("View")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopAppBar, {
      title: "История",
      scrollEl: "#app .content"
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bubbles, (b) => {
        return (_openBlock(), _createBlock(_component_Item, { id: b }, null, 8, ["id"]))
      }), 256))
    ]),
    _createVNode(_component_View)
  ], 64))
}