
import { defineComponent } from "vue";
import Item from "@/components/history/item.vue";
import View from "@/components/history/view.vue";

export default defineComponent({
  name: "History View",

  components: { Item, View },

  computed: {
    bubbles() {
      return this.$fb.users.state.bubbles;
    },
  },
});
