import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e59d074"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = {
  key: 0,
  class: "skeleton img"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info" }
const _hoisted_5 = {
  key: 0,
  class: "skeleton label"
}
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "extra" }
const _hoisted_8 = {
  key: 0,
  class: "skeleton text"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: 2,
  class: "skeleton text"
}
const _hoisted_11 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
    class: _normalizeClass(["item-container", { loading: _ctx.loading }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.avatarUrl,
            draggable: "false"
          }, null, 8, _hoisted_3))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "title-medium",
            textContent: _toDisplayString(_ctx.bubble?.description)
          }, null, 8, _hoisted_6)),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "body-small",
              textContent: _toDisplayString(_ctx.filesCount)
            }, null, 8, _hoisted_9)),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10))
          : (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: "body-small",
              textContent: _toDisplayString(_ctx.formatDate(_ctx.bubble?.timestamp ?? new Date()))
            }, null, 8, _hoisted_11))
      ])
    ])
  ], 2))
}